.accordion{}

.accordion-item{
    background-color: #eef5f9;
    border: 1px solid #eae7e7;;
}

.accordion-item:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.accordion-item:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.accordion-header {
    margin-bottom: 0;
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.accordion-item:last-of-type .accordion-button {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}



.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 500;
    color: #67757c;
    text-align: left;
    background-color: #f9fbfc;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
    &:after {
        content: "";
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        background-image:url("/img/cp/custom-icons/chevron-up.svg");
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform .2s ease-in-out;
    }
    &:focus{outline: none;}
}

.accordion-button.collapsed {
    color: #49565e;
    background-color: #f0f7f9;
    box-shadow: inset 0 -1px 0 #d5e7ee;
    &:after{
        content: "";
        background-image:url("/img/cp/custom-icons/chevron-up.svg");
        transform: rotate(-180deg);
    }
}




/*.collapse:not(.show) {
    display: none;
}*/
.accordion-body {
    padding: 1rem 1.25rem;
    .accordion-collapse.collapse.show &{
        background:#fff;
    }
}